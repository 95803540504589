import './bootstrap';
import { initFlowbite } from'flowbite';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

var themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
var themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');

// Change the icons inside the button based on previous settings
if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    if(themeToggleLightIcon !== null)
        themeToggleLightIcon.classList.remove('hidden');
} else {
    if(themeToggleDarkIcon !== null)
        themeToggleDarkIcon.classList.remove('hidden');
}

var themeToggleBtn = document.getElementById('theme-toggle');
if(themeToggleBtn !== null) {
    themeToggleBtn.addEventListener('click', function() {

        // toggle icons inside button
        themeToggleDarkIcon.classList.toggle('hidden');
        themeToggleLightIcon.classList.toggle('hidden');
    
        // if set via local storage previously
        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            }
    
        // if NOT set via local storage previously
        } else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            } else {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            }
        }
        
    });    
}

Livewire.hook('commit', ({ component, commit, respond, succeed, fail }) => {
    succeed(({ snapshot, effect }) => {
        queueMicrotask(() => {
            initFlowbite();
        })
    })
})

document.addEventListener('livewire:navigated', () => {

    let products = document.getElementsByClassName("product");

    if(products !== null) {
        for(var i = 0; i < products.length; i++) {
            products[i].addEventListener("mouseenter", function(e) {
                if(e.target.getElementsByClassName("product_animate")[0] !== undefined) {
                    e.target.getElementsByClassName("product_animate")[0].style.transform = 'rotate(-5deg)';
                    if(window.innerWidth < 430) {
                        e.target.getElementsByClassName("product_animate")[0].style.backgroundSize = '65% auto';
                    }
                    else {
                        e.target.getElementsByClassName("product_animate")[0].style.backgroundSize = '95% auto';
                    }
                }
            }); 

            products[i].addEventListener("mouseleave", function(e) {
                if(e.target.getElementsByClassName("product_animate")[0] !== undefined) {
                    e.target.getElementsByClassName("product_animate")[0].style.transform = 'rotate(0deg)';
                    e.target.getElementsByClassName("product_animate")[0].style.backgroundSize = 'contain';
                }
            }); 
        }
    }
    
    let searchBox = document.getElementById("searchInput");

    if(searchBox !== null) {
        searchBox.addEventListener("click", function() {
            this.removeAttribute("placeholder");
        });
    }


    const swiper = new Swiper('.swiper', {

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        autoplay: {
            delay: 5000,
        },

        modules: [Navigation, Pagination, Autoplay],

    });

    const elements = document.querySelectorAll('.sticky_header');
    let isVisible = true;
    let hideTimeout; // To hold the timeout reference for hiding

    window.addEventListener('scroll', function() {
        const currentScrollY = window.scrollY;

        // Clear the previous hide timeout if scrolling continues
        clearTimeout(hideTimeout);

        // Use 180 and 15 thresholds to show/hide
        if (currentScrollY > 180 && isVisible) {

                elements.forEach(element => {
                    element.classList.add('hidden'); // Add class to hide
                });
                isVisible = false; // Update visibility status
    
        } else if (currentScrollY < 15 && !isVisible) {
            hideTimeout = setTimeout(function() {
                elements.forEach(element => {
                    element.classList.remove('hidden'); // Remove class to show
                });
           
                isVisible = true; // Update visibility status
            }, 500);
        }
    });

    initFlowbite();
})